import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { SafeAreaView, View, Text, Image, StatusBar } from 'react-native';
import { useSelector } from 'react-redux';
import { Primary } from "../Utility/Utility";
import HomeWhiteIcon from '../Assets/Images/home-icon-white.png';
import HomeBlackIcon from '../Assets/Images/home-icon-black.png';
import OrderWhiteIcon from '../Assets/Images/order-icon-white.png';
import OrderBlackIcon from '../Assets/Images/order-icon-black.png';
import PostionWhiteIcon from '../Assets/Images/position-icon-white.png';
import PostionBlackIcon from '../Assets/Images/position-icon-black.png';
import TradeWhiteIcon from '../Assets/Images/trades-icon-white.png';
import TradeBlackIcon from '../Assets/Images/trades-icon-black.png';

// SCREENS
import Login from '../Screens/Login/Login';
import MarketWatch from '../Screens/MarketWatch/MarketWatch';
import Orders from '../Screens/Orders/Orders';
import Trades from '../Screens/Trades/Trades';
import Positions from '../Screens/Positions/Positions';
import DrawerContent from '../Components/Drawer/DrawerContent';
import ChangePassword from '../Screens/ChangePassword/ChangePassword';
import Scripts from '../Screens/MarketWatch/Scripts';
import LoginUsers from '../Screens/LoginUsers/LoginUsers';
import { useDispatch } from 'react-redux';
import { resetAuth } from '../Actions/AuthActions';
import PositionTrades from '../Screens/Positions/PositionTrades';
import Setting from '../Screens/Setting/Setting';
import ThemeScreen from '../Screens/Setting/ThemeScreen';
import { useTheme } from '../Contexts/ThemeProvider';
import RejectionReport from '../Screens/RejectionReport/RejectionReport';
import EditScripts from '../Screens/MarketWatch/EditScripts';
import QuantityReport from '../Screens/QuantityReport/QuantityReport';
import Notification from '../Screens/Notification/Notification';
import LedgerReport from '../Screens/LedgerReport/LedgerReport';
import LedgerDetail from '../Screens/LedgerDetail/LedgerDetail';
import Margin from '../Screens/Margin/Margin';

const Routes = () => {
    const { dark, colors } = useTheme();
    const loginSuccess = useSelector(state => state.auth.loginSuccess)
    const dispatch = useDispatch()

    const Stack = createStackNavigator();
    const Tab = createBottomTabNavigator();
    const Drawer = createDrawerNavigator();

    // useEffect(() => {
    //     dispatch(resetAuth())
    // }, [])

    const MyDrawer = () => {
        return (
            <Drawer.Navigator
                screenOptions={{ headerShown: false, allowFontScaling: false }}
                drawerContent={(props) => <DrawerContent {...props}
                />}>
                <Drawer.Screen name="HomeTabs" component={HomeTabs} />
            </Drawer.Navigator>
        )
    }

    const HomeTabs = () => {
        return (
            <SafeAreaView
                style={{
                    flex: 1,
                    backgroundColor: '#dff2ff',

                }}>
                <Tab.Navigator
                    initialRouteName='MarketWatch'
                    screenOptions={{
                        headerShown: false, tabBarShowLabel: false,
                        tabBarStyle: { paddingBottom: 16, height: 65, elevation: 500, top: 1, backgroundColor: '#3c5ab0' },
                    }}
                >
                    <Tab.Screen
                        name="MarketWatch"
                        component={MarketWatch}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={{ alignItems: 'center', alignItems: 'center', marginTop: 20, backgroundColor: focused && '#151741', padding: focused ? 5 : 0, borderRadius: 10, marginBottom: 2 }}>
                                        {
                                            dark && <Image source={HomeWhiteIcon} style={{ width: 28, height: 28 }} />

                                        }
                                        {
                                            !dark && <Image source={focused ? HomeWhiteIcon : HomeBlackIcon} style={{ width: focused ? 32 : 28, height: focused ? 32 : 28 }} />
                                        }
                                        <Text
                                            style={{ fontSize: focused ? 13 : 12, lineHeight: 14, color: focused ? colors.Primary : colors.Secondary, marginTop: 5, marginBottom: 2, fontWeight: '500' }}>
                                            MarketWatch
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Orders"
                        component={Orders}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={{ alignItems: 'center', alignItems: 'center', marginTop: 20, backgroundColor: focused && '#151741', padding: focused ? 5 : 0, borderRadius: 10, width: 80, marginBottom: 2 }}>
                                        {
                                            dark && <Image source={OrderWhiteIcon} style={{ width: 28, height: 28 }} />
                                        }
                                        {
                                            !dark && <Image source={focused ? OrderWhiteIcon : OrderBlackIcon} style={{ width: focused ? 32 : 28, height: focused ? 32 : 28 }} />
                                        }
                                        <Text
                                            style={{ fontSize: focused ? 13 : 12, fontFamily: 'Roboto-Medium', lineHeight: 14, color: focused ? colors.Primary : colors.Secondary, marginTop: 5, marginBottom: 2 }}>
                                            Orders
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Trades"
                        component={Trades}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={{ alignItems: 'center', alignItems: 'center', marginTop: 20, backgroundColor: focused && '#151741', padding: focused ? 5 : 0, borderRadius: 10, width: 80, marginBottom: 2 }}>
                                        {
                                            dark && <Image source={TradeWhiteIcon} style={{ width: 28, height: 28 }} />
                                        }
                                        {
                                            !dark && <Image source={focused ? TradeWhiteIcon : TradeBlackIcon} style={{ width: focused ? 32 : 28, height: focused ? 32 : 28 }} />
                                        }
                                        <Text
                                            style={{ fontSize: focused ? 13 : 12, fontFamily: 'Roboto-Medium', lineHeight: 14, color: focused ? colors.Primary : colors.Secondary, marginTop: 5, marginBottom: 2 }}>
                                            Trades
                                        </Text>
                                    </View>
                                )
                            }
                        }}
                    />
                    <Tab.Screen
                        name="Positions"
                        component={Positions}
                        options={{
                            tabBarIcon: ({ focused }) => {
                                return (
                                    <View style={{ alignItems: 'center', alignItems: 'center', marginTop: 20, backgroundColor: focused && '#151741', padding: focused ? 5 : 0, borderRadius: 10, width: 80, marginBottom: 2 }}>
                                        {
                                            dark && <Image source={PostionWhiteIcon} style={{ width: 28, height: 28 }} />
                                        }
                                        {
                                            !dark && <Image source={focused ? PostionWhiteIcon : PostionBlackIcon} style={{ width: focused ? 32 : 28, height: focused ? 32 : 28 }} />
                                        }
                                        <Text
                                            style={{ fontSize: focused ? 13 : 12, fontFamily: 'Roboto-Medium', lineHeight: 14, color: focused ? colors.Primary : colors.Secondary, marginTop: 5, marginBottom: 2 }}>
                                            Positions
                                        </Text>
                                    </View>
                                )
                            },
                        }} />
                </Tab.Navigator>
            </SafeAreaView>
        )
    }

    const MyStack = () => {
        return (
            <Stack.Navigator screenOptions={{
                headerShown: false,
                orientation: 'portrait',
            }}>
                {
                    loginSuccess ? (
                        <>
                            <Stack.Screen name="MyDrawer" component={MyDrawer} />
                            <Stack.Screen name="HomeTabs" component={HomeTabs} />
                            <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Scripts" component={Scripts} options={{ animationEnabled: false }} />
                            <Stack.Screen name="PositionTrades" component={PositionTrades} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Setting" component={Setting} options={{ animationEnabled: false }} />
                            <Stack.Screen name="ThemeScreen" component={ThemeScreen} options={{ animationEnabled: false }} />
                            <Stack.Screen name="RejectionReport" component={RejectionReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="QuantityReport" component={QuantityReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="EditScripts" component={EditScripts} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Notification" component={Notification} options={{ animationEnabled: false }} />
                            <Stack.Screen name="LedgerReport" component={LedgerReport} options={{ animationEnabled: false }} />
                            <Stack.Screen name="LedgerDetail" component={LedgerDetail} options={{ animationEnabled: false }} />
                            <Stack.Screen name="Margin" component={Margin} options={{ animationEnabled: false }} />
                        </>
                    )
                        :
                        (
                            <>
                                <Stack.Screen name="LoginUsers" component={LoginUsers} />
                                <Stack.Screen name="Login" component={Login} />
                            </>
                        )
                }

            </Stack.Navigator>
        )
    }
    return (
        <NavigationContainer>
            <StatusBar
                animated={true}
                backgroundColor="black"
            />
            <MyStack />
        </NavigationContainer>
    )
}

export default Routes;